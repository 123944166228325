import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import useSelectedProperty from '../../../../hooks/useSelectedProperty';

import messages from './messages';
import { formatCurrency } from '../../../../utils/index';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../../../../components/FormattedDateOrDashes';
import DocumentService from '../../../../services/documentService';

type Props = {
  intl: Object,
  camHistoryLoading: boolean,
  camHistory: Array<Object>,
};

const CAMHistory = ({ intl, camHistoryLoading, camHistory }: Props) => {
  const {
    camReconciliationReport: camReconciliationReportFlag,
    camEstimationReport: camEstimationReportFlag,
  } = useFlags();
  const selectedProperty = useSelectedProperty();
  const [showTable, setShowTable] = useState(true);
  const hasHistory = camHistory.length > 0;
  const hideShowMessage = showTable ? (
    <FormattedMessage {...messages.hide} />
  ) : (
    <FormattedMessage {...messages.show} />
  );

  return (
    <React.Fragment>
      <Row className="padtop10 padbottom10 padleft5">
        <Col xs={6}>
          <h2>
            <i className="et-clipboard text-blue padright10" />
            <FormattedMessage {...messages.camHistory} />
          </h2>
        </Col>
        <Col xs={6}>
          <a
            className="pull-right padright10"
            onClick={() => setShowTable(!showTable)}
          >
            {hideShowMessage}
          </a>
        </Col>
      </Row>

      {!camHistoryLoading &&
        showTable &&
        (hasHistory ? (
          <div className="table-scroll table-units-container">
            <table className="table table-fixed-headers table-prospects table-striped">
              <thead className="table-header">
                <tr>
                  <th>
                    <FormattedMessage {...messages.type} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.completedOn} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.completedBy} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.amount} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.report} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderHistoryRows({
                  intl,
                  camHistory,
                  camReconciliationReportFlag,
                  camEstimationReportFlag,
                  propertyId: selectedProperty.id,
                  organizationId: selectedProperty.organizationId,
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <Row className="padtop10 padbottom10 padleft20 font-bold">
            <FormattedMessage {...messages.noHistory} />
          </Row>
        ))}
    </React.Fragment>
  );
};

const downloadReport = async ({
  organizationId,
  propertyId,
  reportId,
  type,
}) => {
  try {
    toastr.info('Pending', 'Downloading report...');

    const documentService = new DocumentService();

    const file = await documentService.download(
      organizationId,
      reportId,
      propertyId,
    );

    FileSaver.saveAs(file, `${type} Report.pdf`);
  } catch (error) {
    let message = typeof error === 'string' ? error : error.toString();

    if (message.includes('[object Object]')) {
      message = 'Failed to download report.';
      console.error(error); // eslint-disable-line
    }

    toastr.error('Error', message);
  }
};

const renderHistoryRows = ({
  intl,
  camHistory,
  camReconciliationReportFlag,
  camEstimationReportFlag,
  propertyId,
  organizationId,
}) => {
  return (
    camHistory &&
    camHistory.map((camHistory, i) => {
      const { type, completedOn, amount, reportId } = camHistory;

      const firstName = camHistory.completedBy?.firstName;
      const lastName = camHistory.completedBy?.lastName;
      const fullName = `${lastName} ${firstName}`;
      const completedBy = fullName === ' ' ? null : fullName;

      const formattedAmount =
        amount === 0 ? '$0' : formatCurrency(intl, +amount);
      const displayAmount = amount !== null ? formattedAmount : '---';

      return (
        <tr key={i}>
          <td>
            <DashesIfNullOrUndefined data={type} />
          </td>
          <td>
            <FormattedDateOrDashes
              value={completedOn}
              format="YYYY/MM/DD hh:mm:ss A"
            />
          </td>
          <td>
            <DashesIfNullOrUndefined data={completedBy} />
          </td>
          <td>{displayAmount}</td>
          <td>
            <Button
              id="download-button"
              bsStyle="default"
              bsSize="small"
              onClick={() => {
                downloadReport({
                  reportId,
                  type,
                  propertyId,
                  organizationId,
                });
              }}
              disabled={
                (!camReconciliationReportFlag && !camEstimationReportFlag) ||
                !reportId
              }
            >
              <i className="icon et-download" />
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default CAMHistory;
